import type { Ref } from "vue";
import type { LotItemInterface } from "@/stores/search/LotItemInterface";
import type { FilteredEntity, MainStreamListInfo, SupplementalPayloadI } from "@/utils/getters/defaultRequestInfo";
import { useAbort, useApi } from "@/use/api/useApi";
import { useSuperAccount } from "@/use/superAccount/useSuperAccount";
import { prepareNotEmptyPreFiltrationAndSorting } from "@/utils/searchForm/searchFormWorker";
import { getErrorMessage } from "~/use/users/useUserModifier";
import {
  getDefaultPreFiltrationFormApp,
  getDefaultPreFiltrationFormContract
} from "@/utils/getters/defaultFilters";

export function preRequest(state: FilteredEntity, cancel: Function) {
  if (state.info.request) state.info.request.cancel();

  state.info.loading = true;
  state.info.request = { cancel: cancel }
  state.info.error = false;
  state.info.isOver = false;
}

export function afterRequest(state: FilteredEntity, error: boolean, count = 0) {
  state.info.loading = false;
  state.info.request = null;
  state.info.error = error;
  state.info.count = count;
}

export function getOptionsMeta(data: any) {
  return {
    count: data.count || 0,
    sumCost: data.sumCost || 0,
    unseenCountLots: data.unseenCountLots || null,
    unseenSumCost: data.unseenSumCost || null,
    selective: Boolean(data.selective),
    currency: data?.currency?.alpha || 'RUB',
  }
}

export function getDefaultOptionsMeta() {
  return {
    count: 0,
    sumCost: 0,
    unseenCountLots: null,
    unseenSumCost: null,
    selective: true,
    currency: 'RUB',
  }
}

function getSuppFunction(mode: 'search'|'templates'|'apps'|'contracts') : (payload: any, signal: AbortSignal) => Promise<unknown> {
  switch(mode) {
    case 'search': return useApi().supplemental.fetchSearchSupplemental;
    case 'templates': return useApi().supplemental.fetchTemplatesSupplemental;
    case 'apps': return useApi().supplemental.fetchAppsSupplemental;
    case 'contracts': return useApi().supplemental.fetchContractsSupplemental;
  }
}

function getSearchFunction(mode: 'search'|'templates'|'apps'|'contracts') : (payload: any, offset: number, signal?: AbortSignal) => Promise<unknown> {
  switch(mode) {
    case 'search': return useApi().request.fetchSearchList;
    case 'templates': return useApi().request.fetchTemplatesList;
    case 'apps': return useApi().request.fetchAppsList;
    case 'contracts': return useApi().request.fetchContractsList;
  }
}

export function commonSupplemental(info: Ref<MainStreamListInfo>, fetchData: any, mode: 'search'|'templates'|'apps'|'contracts') {
  return new Promise<SupplementalPayloadI>((resolve, reject) => {

    if (info.value.options.request) info.value.options.request.cancel();

    const { signal, abort, } = useAbort();

    info.value.options.loading = true;
    info.value.options.request = { cancel: abort };

    const suppFunction = getSuppFunction(mode)

    suppFunction(fetchData, signal)
      .then((data) => {
        if (data) info.value.options.info = getOptionsMeta(data)
        else info.value.options.info = getDefaultOptionsMeta()
        info.value.options.request = null;
        info.value.options.loading = false;
        resolve(data?.unseenCountLots || 0)
      })
      .catch((error) => {
        if (!signal.aborted) {
          info.value.options.info = getDefaultOptionsMeta();
          info.value.options.request = null;
          info.value.options.loading = false;
        }
      })
  })
}

export function cancelRequest(info: Ref<MainStreamListInfo>) {
  if (info.value.meta.request) {
    info.value.meta.request.cancel();
    info.value.meta.request = null;
  }

  if (info.value.options.request) {
    info.value.options.request.cancel();
    info.value.options.request = null;
  }
}

export function getEntitiesList<T extends LotItemInterface>(info: Ref<MainStreamListInfo>, fetchData: any, mode: 'search'|'templates'|'apps'|'contracts') {
  return new Promise<Array<T>>((resolve, reject) => {
    if (info.value.meta.isOver) reject(null)
    else {
      if (info.value.meta.request) info.value.meta.request.cancel();

      const { signal, abort, } = useAbort();

      info.value.meta.loading = true;
      info.value.meta.error = false;
      info.value.meta.request = { cancel: abort };

      const searchFunction = getSearchFunction(mode)

      searchFunction(fetchData, info.value.meta.part, signal)
        .then((response) => {
          const data = response || []

          if (data.length) info.value.meta.part++;
          else info.value.meta.isOver = true;

          resolve(data)
        })
        .catch((error) => {
          if (!signal.aborted) {
            info.value.meta.error = true;
            reject(getErrorMessage(error?.response?.status, error?.data, 'Произошла ошибка при запросе данных'))
          }
        })
        .finally(() => {
          if (!signal.aborted) {
            info.value.meta.request = null;
            info.value.meta.loading = false;
          }
        })
    }
  })
}

function prepareCommonData(ids: number[], pageStatuses: number[], filters: any, companyId: number|null, field: 'contractStatusIds'|'appStatusIds', defaultFilters: any) {
  const params: any = {};

  if (ids) {
    params.templateIds = ids.filter((e: number) => e !== -2);
    params.withoutTemplate = ids.includes(-2);
  }

  params[field] = filters[field].length
    ? filters[field]
    : pageStatuses;

  if (companyId) {
    params.companyId = companyId
  }

  const pfilters = prepareNotEmptyPreFiltrationAndSorting(filters, defaultFilters);

  return {
    ...params,
    ...pfilters,
  }
}

export function prepareContractData(ids: number[], pageStatuses: number[], filters: any) {
  return prepareCommonData(ids, pageStatuses, filters, useSuperAccount().managerCompanyId.value, 'contractStatusIds', getDefaultPreFiltrationFormContract())
}

export function prepareAppData(ids: number[], pageStatuses: number[], filters: any) {
  return prepareCommonData(ids, pageStatuses, filters, useSuperAccount().managerCompanyId.value, 'appStatusIds', getDefaultPreFiltrationFormApp())
}
